//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from "react";
import {FormattedMessage, injectIntl} from 'react-intl';

class Main extends Component {

    render() {
        return (
            <div>
                <section id="mission" className="mission">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h1>
                                <FormattedMessage 
                                    id = "app.main-mission"
                                    defaultMessage="Our Mission"
                                />
                            </h1>
                            <p>
                                <FormattedMessage
                                    id = "app.main-mission-desc-1"
                                    defaultMessage="
                                    We are here to provide you with the best service possible without charging you for features your site doesn't really need.{sp}{sp}</>We DO NOT want to break your bank account."
                                    values={{
                                        sp: <>&nbsp;</>
                                    }}
                                />
                            </p>
                            <p>
                                <FormattedMessage 
                                    id = "app.main-mission-desc-2"
                                    defaultMessage="
                                    Our developers are 100% Spanish/English certified technicians with over 20 years of experience in translations.{sp}{sp}We do NOT use translation engines like Google or Microsoft, we actually translate your context with the language concept in mind.
                                    "
                                    values={{
                                        sp: <>&nbsp;</>
                                    }}
                                />
                            </p>
                            <p>
                                <FormattedMessage 
                                    id = "app.main-mission-desc-3"
                                    defaultMessage="
                                    The reason we don't have an annual contract is because we want you to feel free to find other services that may fit your needs if you are not satisfied with ours.
                                    "
                                />
                            </p>
                        </div>
                        
                    </div>
                </section>
            </div>
        );
    }
}

export default injectIntl(Main);