//--------------------------------//
//- Author: Francisco J. O'Meany -//
//- February 28, 2024            -//
//--------------------------------//
import React, { Component } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

export default class MapSection extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const self = this;
        const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

        const loader = new Loader({
            apiKey: apiKey,
            version: "weekly",
            libraries: ["places"],
            language: self.props.lang
        });
        
        const defaultMapOptions = {
            zoom: self.props.zoom,
            mapId: 'NETEQUAL_MAP_ID'    //- Required for AdvancedMarkerElement
        };

        loader.load().then((google) => {
            //const { AdvancedMarkerElement } =  google.maps.importLibrary("marker");
            google.maps.importLibrary("marker");
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': self.props.address}, function(results, status) {

                const contentString = 
                `
                <div id="content">
                    <div id="siteNotice"></div>
                    <h3 id="firstHeading" class="firstHeading">Netequal Technologies</h3>
                    <div id="bodyContent">
                        <p>We will connect your business goals to your user's needs by establishing the relationship with your customers throughout a developed process.</p>
                        <p>Stockton, California</p>
                    </div>
                </div>
                `;

                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                    ariaLabel: "Netequal",
                });


                if (status === 'OK') {
                    const map = new google.maps.Map(
                        self.googleMapDiv,
                        defaultMapOptions
                    );
            
                    map.setCenter(results[0].geometry.location);

                    const marker = new google.maps.marker.AdvancedMarkerElement({
                        map: map,
                        position: results[0].geometry.location,
                        title: self.props.address
                    });

                    marker.addListener("click", () => {
                        infowindow.open({
                          anchor: marker,
                          map,
                        });
                      });
                    

                    //    store them in the state so you can use it later
                    //    E.g. call a function on the map object:
                    //        this.state.map.panTo(...)
                    //    E.g. create a new marker:
                    //        new this.state.google.maps.Marker(...)
                    self.setState({
                        google: google,
                        map: map
                    });
                }
            });
        });
    }

    render() {
        return (
            <div
                ref={(ref) => { this.googleMapDiv = ref }}
                style={{ height: '50vh', width: '100%' }}>
            </div>
        )
    }
}