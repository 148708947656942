//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';

class Hero extends Component {

     componentDidMount() {
       
     }

    render() {
      return (
        <div>
          <section id="neHero" className="d-flex align-items-center ne-fullscreen neMainSection">
            <div className="ne-overlay"></div>
            <div className="container text-center w-50" data-aos="zoom-out" data-aos-delay="150">
              <div className="row">
                <div className="col-sm-12">
                  <h1>
                    <FormattedMessage 
                      id = "app.hero-1"
                      defaultMessage="Creating bilingual solutions for small businesses"
                    />
                  </h1>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-sm-12">
                  <h2 className="color-white">
                    <FormattedMessage 
                      id = "app.hero-2"
                      defaultMessage="Using renewable energy powered servers for all our websites!"
                    />
                  </h2>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
}

export default Hero;
