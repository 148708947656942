//-----------------------------------//
//- Author: Francisco J. O'Meany    -//
//- March 06, 2024                  -//
//-----------------------------------//
import React from "react";
import {FormattedMessage, injectIntl} from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import MapSection from "./MapComponent";
import Utility from "./UtilityComponent";
import axios from 'axios';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        const self      = this;
        const utl       = new Utility();
        this.utl        = utl;
        this.state      = { stateOpt: [{update: false, date: new Date()}] };
        this.on         = utl.on;
        this.lang       = document.getElementsByTagName('html')[0].getAttribute('lang');
        this.language   = this.lang;
        this.captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
        this.captchaRef = React.createRef();
        this.intl       = this.props.intl;
        this.address    = "Stockton, CA";
        this.zoom       = 15;

        let timerFld = setInterval(function() {
            let events = ['keyup','change','cut','paste'];
            let msgEvt = [ 'keyup' ];
            utl.on( events, '.form-control', function(e) {
                self.handleSubmit();
            }, true);

            utl.on( msgEvt, '#name, #email, #subject, #message', function(e) {
                let sendMsgEl = document.getElementById("neSendMsg");
                sendMsgEl.classList.add("d-none");
            });

            if( document.getElementById("name") !== null ) {
                clearInterval( timerFld );
            }
        }, 300);
    }

    componentDidMount = () => {
        this.setState({
            stateOpt: [
              {
                update: false,
                date: new Date()
              }
            ]
        });
    }

    onChange = ( value ) => {
        this.utl.appLogger("captcha value from onChange:", value);
        this.validateForm( value );
        return;
    }

    validateForm = ( value ) => {
        let element = document.getElementById("neSendBtn");
        let formStatus = false;
    
        this.utl.appLogger("validateForm value: ", value );
        if( value === null || value === "" ) {
            this.utl.appLogger("captcha value is null");
            element.classList.add("disabled");
        } else {
            if( document.getElementById("name").value !== '' &&
                document.getElementById("email").value !== '' && 
                document.getElementById("subject").value !== '' && 
                document.getElementById("message").value !== '' ) {
                element.classList.remove("disabled");
                formStatus = true;
            } else {
                element.classList.add("disabled");
            }
        }
    
        return( formStatus );
    }

    handleSubmit = () => {
        if( this.captchaRef !== null && this.captchaRef.current !== null) {
            let token = this.captchaRef.current.getValue();
            let self = this;
            self.utl.appLogger("reCaptcha token from handleSubmit: ", ( token === null ? "NULL" : ( token === "" ? "BLANK" : "UNKNOWN" ) ) );

            if( this.validateForm( token ) ) {
                //- Send message here
                let sendBtnEl = document.getElementById("neSendBtn");
                let sendMsgEl = document.getElementById("neSendMsg");
                let update    = this.state.stateOpt[0].update;

                sendBtnEl.classList.add("disabled");
                sendMsgEl.classList.add("d-block");

                this.setState({
                    stateOpt: [
                      {
                        update: ( update === false ? true : false ),
                        date: new Date()
                      }
                    ]
                });

                let timerState = setInterval(function() {
                    if( update !== self.state.stateOpt[0].update ) {
                        clearInterval( timerState );
                        
                        const data = {
                            "method": 10,
                            "email": document.getElementById("email").value,
                            "subject": document.getElementById("subject").value,
                            "msg": document.getElementById("message").value
                        }

                        //- Send email
                        let server = ( window.location.host === "localhost:3000" ? "./public/api/neAgent.php" : "/api/neAgent.php" );
                        axios.post( server, data, {
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8'
                              }
                        })
                        .then(function (response) {
                            self.utl.appLogger( "axios response: ", response);
                            //- Clear all fields and reset captcha
                            document.getElementById("name").value = "";
                            document.getElementById("email").value = "";
                            document.getElementById("subject").value = "";
                            document.getElementById("message").value = "";
                            if (window.grecaptcha) window.grecaptcha.reset();
                            sendBtnEl.classList.remove("disabled");
                            sendMsgEl.classList.remove("d-none");
                        })
                        .catch(function (error) {
                            self.utl.appLogger( "axios error: ", error);
                        });
                    }
                    
                }, 250);
            }
        }
    
        return;
    }

    render() {
        const self = this;
        return(
            <div>
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title pb-3">
                            <h1>
                                <FormattedMessage 
                                    id = "app.contact-title"
                                    defaultMessage="Contact"
                                />
                            </h1>
                            <p>
                                <FormattedMessage 
                                    id = "app.contact-desc"
                                    defaultMessage="We respect your privacy, that's the reason we never share your personal information."
                                />
                            </p>
                        </div>
    
                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <div id="netequalContact" className="col-lg-6">
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <div className="info-box  mb-4">
                                            <i className="fas fa-envelope fa-2x"></i>
                                            <h3>
                                                <FormattedMessage 
                                                    id = "app.email-us-title"
                                                    defaultMessage="Email Us"
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <form id="main-contact-form" name="contact-form" className="php-email-form" noValidate>
                                    <input type="hidden" id="method" name="method" value='10' />
                                    <div className="row">
                                        <div className="col form-group mb-4">
                                            <input type="text" name="name" className="form-control" id="name" title={this.intl.formatMessage({ id: 'app.your-name-tip' })} placeholder={this.intl.formatMessage({ id: 'app.your-name' })} required autoComplete="on" />
                                        </div>
                                        <div className="col form-group mb-4">
                                            <input type="email" className="form-control" name="email" id="email" title={this.intl.formatMessage({ id: 'app.your-email-tip' })} placeholder={this.intl.formatMessage({ id: 'app.your-email' })} required autoComplete="on" />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-4">
                                        <input type="text" className="form-control" name="subject" id="subject" title={this.intl.formatMessage({ id: 'app.subject-tip' })} placeholder={this.intl.formatMessage({ id: 'app.subject' })} required />
                                    </div>
                                    <div className="form-group mb-4">
                                        <textarea lang={this.language} className="form-control" name="message" id="message" rows="5" title={this.intl.formatMessage({ id: 'app.message-tip' })} placeholder={this.intl.formatMessage({ id: 'app.message' })} required></textarea>
                                    </div>
                                
                                    <div className="text-center mb-4">
                                        <ReCAPTCHA 
                                            sitekey={ this.captchaKey } className="d-flex justify-content-center"
                                            onChange={this.onChange}
                                            hl={this.language}
                                            ref={this.captchaRef}
                                        />
                                    </div>
                                    
                                    <div className="text-center">
                                        <button id="neSendBtn" type="button" className="btn btn-primary disabled" onClick={self.handleSubmit}>
                                            <FormattedMessage 
                                                id = "app.send-btn"
                                                defaultMessage="Send message"
                                            />
                                        </button>
                                    </div>
    
                                    <div className="my-3">
                                        <div id="neSendMsg" className="d-none">
                                            <FormattedMessage 
                                                id = "app.send-msg"
                                                defaultMessage="Your message has been sent.{sp}{sp}Thank you."
                                                values={{
                                                    sp: <>&nbsp;</>
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="netequalMap" className="col-lg-6 ">
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <div className="info-box mb-4">
                                            <i className="fas fa-map-marker-alt fa-2x"></i>
                                            <h3>
                                                <FormattedMessage 
                                                    id = "app.address-title"
                                                    defaultMessage="Our Address"
                                                />
                                            </h3>
                                            <p>Stockton, CA 95267-0122</p>
                                        </div>
                                    </div>
                                </div>
                                <MapSection address={this.address} lang={this.lang} zoom={this.zoom} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default injectIntl( Contact );