//--------------------------------//
//- Author: Francisco J. O'Meany -//
//- February 26, 2024            -//
//--------------------------------//
import React from "react";
import { FormattedMessage } from 'react-intl';

class Footer extends React.Component {
    getYear() {
        let today = new Date();
        return( today.getFullYear() );
    }
    render() {
        return(
            <div>
                <footer id="footer">
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="copyright text-start">
                                    <FormattedMessage
                                        id = "app.footer-copyright"
                                        defaultMessage="{copy} {year} Copyright {company}"
                                    values={{
                                        copy: <>&copy;</>,
                                        company: <strong><span>Netequal Technology Solutions.&nbsp;&nbsp;</span></strong>,
                                        year: this.getYear()
                                    }}
                                />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="ne-social-links text-end">
                                    <a href="https://facebook.com/netequaltech" className="facebook" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f fa-2x me-3"></i></a>
                                    <a href="https://twitter.com" className="twitter" target="_blank" rel="noreferrer"><i className="fab fa-twitter fa-2x me-3"></i></a>
                                    <a href="https://instagram.com" className="instagram" target="_blank" rel="noreferrer"><i className="fab fa-google-plus-g fa-2x"></i></a>
                                </div>			
                            </div>
                        </div>
                        <div className="row d-none">
                            <div className="col-md-6 col-sm-12">
                                <small>
                                    <a href="https://www.freepik.com/free-photo/group-diverse-people-having-business-meeting_2894621.htm#fromView=search&page=2&position=5&uuid=d1477ce2-db04-4685-bf14-a40ba4ae6f3f">Image by rawpixel.com</a> on Freepik
                                </small>
                            </div>
                        </div>
                    </div>
	            </footer>
            </div>
        );
    }
}

export default Footer;