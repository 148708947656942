//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React from "react";
import {FormattedMessage} from 'react-intl';

class About extends React.Component {

    render() {
        return(
          <div>
            <section id="about" className="about section-bg">
    		    <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h1>
                            <FormattedMessage 
                                id = "app.about-title"
                                defaultMessage="About Us"
                            />
                        </h1>
                        <p>
                            <FormattedMessage 
                                id = "app.about-desc"
                                defaultMessage="{company} is a bilingual (Spanish/English) web design company specializing in business solutions targeting the Hispanic market.  The Hispanic Internet User is the fastest growing online segment in the United States.  Building your site bilingual is essential to reach the growing Hispanic Market."
                                values={{
                                    company: <b>NetequalTech</b>
                                }}
                            />
                        </p>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <h3 className="text-center">
                                <FormattedMessage 
                                    id = "app.market-title"
                                    defaultMessage="Hispanic Market"
                                />
                            </h3>
                            <p className="text-start">
                                <FormattedMessage 
                                    id = "app.market-desc"
                                    defaultMessage="When it comes to shopping for specific products, 72% of all Hispanics say they believe store branded products are a good alternative to name brands.  That percentage, however, drops to 66% among Hispanics who prefer Spanish over English."
                                />
                            </p>
                            <p className="text-start">
                                <FormattedMessage 
                                    id = "app.facts"
                                    defaultMessage="Facts about Hispanics in the United States:"
                                />
                            </p>
        
                            <div className="row">
                                <div className="col-sm-6">
                                    <ul className="text-start">
                                        <li className="mb-2">
                                            <FormattedMessage 
                                                id = "app.fact-1"
                                                defaultMessage="{check} Hispanics are the fastest growing Internet users in the United States."
                                                values={{
                                                    check: <i className="fa fa-check-square" aria-hidden="true"></i>
                                                }}
                                            />
                                        </li>
                                        <li className="mb-2">
                                            <FormattedMessage 
                                                id = "app.fact-2"
                                                defaultMessage="{check} More than 60% of offline Hispanics cite lack of Spanish content as a reason for not going online."
                                                values={{
                                                    check: <i className="fa fa-check-square" aria-hidden="true"></i>
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
        
                                <div className="col-sm-6">
                                    <ul className="text-start">
                                        <li className="mb-2">
                                            <FormattedMessage 
                                                id = "app.fact-3"
                                                defaultMessage="{check} Hispanics account for almost half of U.S. population growth."
                                                values={{
                                                    check: <i className="fa fa-check-square" aria-hidden="true"></i>
                                                }}
                                            />
                                        </li>
                                        <li className="mb-2">
                                            <FormattedMessage 
                                                id = "app.fact-4"
                                                defaultMessage="{check} U.S. Hispanics currently have $3.4 trillion in purchasing power, a number that's sure to rise in the years to come."
                                                values={{
                                                    check: <i className="fa fa-check-square" aria-hidden="true"></i>
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center mt-2">
                            <a className="btn btn-primary text-center" href="https://latinogdp.us/" target="_blank" rel="noreferrer">
                                <FormattedMessage 
                                    id = "app.see-more"
                                    defaultMessage="See more..."
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
          </div>  
        );
    }
}

export default About