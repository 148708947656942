//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';

class Portfolio extends Component {

    render() {
        return(
            <div>
                <section id="portfolio" className="portfolio">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h1>
                                <FormattedMessage 
                                    id = "app.portfolio-title"
                                    defaultMessage=""
                                />
                            </h1>
                            <p className="neWorksDescText">
                                <FormattedMessage 
                                    id = "app.portfolio-desc"
                                    defaultMessage=""
                                />
                            </p>
                        </div>
            
                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <ul id="portfolio-flters">
                                </ul>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="200">
                            <div className="col-xs-12 col-sm-4 d-flex align-items-stretch">
                                <div className="card">
                                    <img className="card-img-top" src="./assets/images/bocc-iphone.png" alt="" />
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="card-title">
                                            <a href="https://bocc-pcs.net" target="_blank" rel="noreferrer"> San Francisco Department of Public Health - Business Office of Contract Compliance</a>
                                        </h6>
                                        <p className="card-text mb-4">
                                            An Organizational Management system for the Business Office of Contract Compliance.
                                        </p>
                                        <div className="card-read-more mt-auto align-self-start w-100">
                                            <a href="https://bocc-pcs.net" className="btn btn-primary mt-2 align-self-start w-100" target="_blank" rel="noreferrer">
                                                See site
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 d-flex align-items-stretch">
                                <div className="card">
                                    <img className="card-img-top" src="./assets/images/2locos-ipad.png" alt="" />
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="card-title">
                                            <a href="https://hermosaz.com" target="_blank" rel="noreferrer">HERMOSAZ</a>
                                        </h6>
                                        <p className="card-text mb-4">
                                            An on-line magazine and shopping site.
                                        </p>
                                        <div className="card-read-more mt-auto align-self-start w-100">
                                            <a href="https://hermosaz.com" className="btn btn-primary mt-2 align-self-start w-100" target="_blank" rel="noreferrer">
                                                See site
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 d-flex align-items-stretch">
                                <div className="card">
                                    <img className="card-img-top" src="./assets/images/deportations-desktop.png" alt="" />
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="card-title">
                                            <a href="https://deportationsformoney.com" target="_blank" rel="noreferrer">Deportations for Money</a>
                                        </h6>
                                        <p className="card-text mb-4">
                                            A book about money made while deporting illegal individuals.
                                        </p>
                                        <div className="card-read-more mt-auto align-self-start w-100">
                                            <a href="https://deportationsformoney.com" className="btn btn-primary mt-2 align-self-start w-100" target="_blank" rel="noreferrer">
                                                See site
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Portfolio;