import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from "aos";
import "aos/dist/aos.css";
import './App.css';

import Header from './components/HeaderComponent';
import Hero from './components/HeroComponent';
import Main from "./components/MainComponent";
import Services from "./components/ServiceComponent";
import Domain from "./components/DomainComponent"
import Portfolio from "./components/PortfolioComponent";
import About from "./components/AboutComponent";
import Contact from "./components/ContactComponent";
import Footer from "./components/FooterComponent";
import Utility from "./components/UtilityComponent";

class App extends Component {

  componentDidMount() {
    
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });


    /**   * Easy selector helper function   */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    }


    /**   * Easy on scroll event listener    */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener);
    }

    /**     * Navbar links active state on scroll     */
    let navbarlinks = select('#navbar .scrollto', true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      })
    }
    window.addEventListener('load', navbarlinksActive);
    onscroll(document, navbarlinksActive);

    document.getElementById("neHeroMenu").click();

    return;
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Hero />
        <Main />
        <Services />
        <Domain />
        <Portfolio />
        <About />
        <Contact />
        <Footer />
        <Utility />
      </div>
    );
  }
}

export default App;