//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import "//kit.fontawesome.com/a4e1ca6a53.js";

class Services extends Component {

    render() {
        return (
            <div>
                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h1>
                                <FormattedMessage 
                                    id = "app.main-services"
                                    defaultMessage="Our Services"
                                />
                            </h1>
                            <p>
                                <FormattedMessage 
                                    id = "app.main-srv-desc"
                                    defaultMessage="All of our websites are running on servers powered 100% by renewable energy.  We really want to make a big difference with our competition and wanted to minimize the environmental impact caused by our operations."
                                />
                            </p>
                        </div>
                    </div>

    		        <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="fa fa-desktop"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-webdev"
                                            defaultMessage="Web Development"
                                        />
                                        </a>
                                    </h4>
                                    <p className="neWebDevDescText">
                                        <FormattedMessage 
                                            id = "app.services-webdev-desc"
                                            defaultMessage="We do our best to have your website up and running in a very short period of time.  We believe that your satisfaction is very important and that your success is our success.  We work with our clients very closely to make sure we meet their requirements."
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="fa fa-line-chart"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-ux"
                                            defaultMessage="UX Design"
                                        />
                                        </a>
                                    </h4>
                                    <p>
                                        <FormattedMessage 
                                            id = "app.services-ux-desc"
                                            defaultMessage="We will connect your business goals to your user's needs by establishing the relationship with your customers throughout a developed process."
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className="fa fa-cubes"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-ui"
                                            defaultMessage="UI Design"
                                            values={{
                                                sp: <>&nbsp;</>
                                            }}
                                        />
                                        </a>
                                    </h4>
                                    <p>
                                        <FormattedMessage 
                                            id = "app.services-ui-desc"
                                            defaultMessage="Your web's interface is perhaps the most essential and important part of your website.{sp}{sp}Our web designers and engineers pay a great attention and immense important in creating a great web interface."
                                            values={{
                                                sp: <>&nbsp;</>
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="fas fa-globe-americas"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-hosting"
                                            defaultMessage="Green Hosting"
                                        />
                                        </a>
                                    </h4>
                                    <p>
                                        <FormattedMessage 
                                            id = "app.services-hosting-desc"
                                            defaultMessage="Wind energy is fueled by the wind, so it's a clean fuel source.{sp}{sp}Wind energy doesn't pollute the air like power plants that rely on combustion of fossil fuels, such as coal or natural gas.{sp}{sp}Wind turbines don't produce atmospheric emissions that cause acid rain or greenhouse gasses.{sp}{sp}Wind turbines can be built on farms or ranches, thus benefiting the economy in rural areas, where most of the best wind sites are found.{sp}{sp}Farmers and ranchers can continue to work the land because the wind turbines use only a fraction of the land.{sp}{sp}Wind power plant owners make rent payments to the farmer or rancher for the use of the land."
                                            values={{
                                                sp: <>&nbsp;</>
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="fas fa-chalkboard-teacher"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-training"
                                            defaultMessage="Training"
                                        />
                                        </a>
                                    </h4>
                                    <p>
                                        <FormattedMessage 
                                            id = "app.services-training-desc"
                                            defaultMessage=""
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className="fas fa-wrench"></i></div>
                                    <h4><a href="_#">
                                        <FormattedMessage 
                                            id = "app.services-tech"
                                            defaultMessage="Technical Support"
                                        />
                                        </a>
                                    </h4>
                                    <p>
                                        <FormattedMessage 
                                            id = "app.services-tech-desc"
                                            defaultMessage="Our Hourly Support Services plans provide a cost-effective solution to supporting and maintaining your IT infrastructure at an affordable hourly rate."
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Services;