import React from 'react';
import ReactDOM from 'react-dom/client';
import {IntlProvider} from "react-intl";
import './index.css';
import App from './App';
import Utility from './components/UtilityComponent';
import reportWebVitals from './reportWebVitals';
import Spanish from './lang/es_ES.json';
import English from './lang/en_US.json';

const messages = {
  'en': English,
  'es': Spanish
};

let utl = new Utility();
let lng = utl.getCookie("neLang");
if( lng === undefined || lng === '' ) {
  lng = 'en';
  utl.setCookie( "neLang", lng, 30 );
}

document.getElementsByTagName('html')[0].lang = lng;
document.getElementsByTagName('title')[0].innerHTML = "Netequal Technologies - " + ( lng === 'en' ? 'Home' : 'Página Princpal' );

const language = document.getElementsByTagName('html')[0].getAttribute('lang');
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <IntlProvider locale={language} defaultLocale={language} messages={messages[language]}>
        <App/>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();