//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from "react";
import {FormattedMessage, injectIntl} from 'react-intl';
import Utility from "./UtilityComponent";
import axios from 'axios';


class Domain extends Component {
    constructor(props) {
        super(props);
        const utl       = new Utility();
        this.utl        = utl;
        this.state      = { stateOpt: [{update: false, date: new Date()}] };
        this.intl       = this.props.intl;

        let timerFld = setInterval(function() {
            let events = ['keyup','change','cut','paste'];
            
            utl.on( events, '#neDomainName', function(e) {
                e.preventDefault();
                let btn = document.getElementById("neDomainSearch");
                if( document.getElementById("neDomainName").value.length >= 3 ) {
                    btn.classList.remove("disabled");
                } else {
                    btn.classList.add("disabled");
                }
            }, true);

            if( document.getElementById("neDomainName") !== null ) {
                clearInterval( timerFld );
            }
        }, 250);
    }

    searchDomain = () => {
        const self = this;
        let srchName = document.getElementById("neDomainName");
        let srchBtn  = document.getElementById("neDomainSearch");
        let btnImg   = document.getElementById("neDomainSearch").getElementsByTagName("i");

        let domain = document.getElementById("neDomainName").value.split(".");
        domain = domain[0];
        let specificDom = ( domain.lastIndexOf(".") === -1 ? "" : domain );
        const data = {
            "method":11,
            "domain":domain,
            "specific":""       //specificDom
        }

        //- Disable domain name field and search button and add loading gif
        srchName.style = "background: url('/assets/images/load.gif') no-repeat center center";
        btnImg[0].classList.remove("fa-search");
        btnImg[0].classList.add("fa-cog");
        btnImg[0].classList.add("fa-spin");
        document.getElementById("neDomainName").setAttribute("disabled", true);
        srchBtn.classList.add("disabled");
        //- Post domain search data
        let server = ( window.location.host === "localhost:3000" ? "./public/api/neAgent.php" : "/api/neAgent.php" );
        axios.post( server, data, {
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            }
        })
        .then(function (response) {
            //- Remove disabled status and remove loading gif
            srchName.style = "none";
            btnImg[0].classList.remove("fa-cog");
            btnImg[0].classList.remove("fa-spin");
            btnImg[0].classList.add("fa-search");
            document.getElementById("neDomainName").removeAttribute("disabled");
            srchBtn.classList.remove("disabled");

            self.utl.appLogger( "axios response: ", response);
            //- Display results
            let domExt = document.getElementById("neDomResults");
            let domList = ["com","net","org","info","us","biz","house","soy", "me"];
            let dataRet = response.data;

            if( dataRet.indexOf("Fatal error") !== -1 ) {
                domExt.classList.add("d-none");
                return;
            }

            domExt.classList.remove("d-none");
            document.getElementById("neDomReqName").innerHTML = domain;

            //- Hide all extensions
            for(let ndx = 0; ndx < domList.length; ndx++ ) {
                let extAV = document.getElementById( "neExt" + domList[ndx] + "AV" );
                let extNA = document.getElementById( "neExt" + domList[ndx] + "NA" );
                extAV.classList.add("d-none");
                extNA.classList.add("d-none");
                document.getElementById( "neExt" + domList[ndx] + "Tbl" ).style = "color: #000";
            }

            if( specificDom === "" ) {
                let domResp = dataRet[2];
				for(let ndx = 0; ndx < domList.length; ndx++ ) {
                    if( domResp[domList[ndx]] ) {
                        let extAV = document.getElementById( "neExt" + domList[ndx] + "AV" );
                        extAV.classList.remove("d-none");
                        document.getElementById( "neExt" + domList[ndx] + "Tbl" ).style = "color: green";
                    } else {
                        let extNA = document.getElementById( "neExt" + domList[ndx] + "NA" );
                        extNA.classList.remove("d-none");
                        document.getElementById( "neExt" + domList[ndx] + "Tbl" ).style = "color: red";
                    }
                }
            } else {
                for(let ndx = 0; ndx < domList.length; ndx++ ) {
                    document.getElementById( "neExt" + domList[ndx] ).innerHTML = "";
                }
            }
        })
        .catch(function (error) {
            self.utl.appLogger( "axios error: ", error);
        });
    }

    render() {
        return (
            <div>
                <section id="domainSearch" className="domainSearch">
                    <h1 className="section-title text-center" data-aos="zoom-in" data-aos-delay="100">
                        <FormattedMessage 
                            id = "app.domain-search"
                            defaultMessage="Search for a domain name"
                        />
                    </h1>
                    <div className="row text-center">
                        <div className="col-lg-12">
                                <div className="col form-group">
                                    <input className="w-75" type="text" id="neDomainName" name="neDomainName" placeholder={this.intl.formatMessage({ id: 'app.domain-name' })} autoComplete="on" />
                                    <button id="neDomainSearch" className="btn btn-primary ms-1 disabled" title={this.intl.formatMessage({ id: 'app.domain-search-btn' })} 
                                            onClick={this.searchDomain} 
                                            type="submit"><i className="fa fa-search fa-fw" aria-hidden="true"></i>
                                    </button>
                                </div>
                        </div>
                    </div>

                    <div id="neDomainExtensions" className="d-none" data-aos="zoom-in" data-aos-delay="100">
                        <div className="row">
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkAll" />
                                <label className="form-check-label" htmlFor ="neChkAll">All</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkCom" />
                                <label className="form-check-label" htmlFor ="neChkCom">.com</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkNet" />
                                <label className="form-check-label" htmlFor ="neChkNet">.net</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkOrg" />
                                <label className="form-check-label" htmlFor="neChkOrg">.org</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkInfo" />
                                <label className="form-check-label" htmlFor="neChkInfo">.info</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkUs" />
                                <label className="form-check-label" htmlFor="neChkUs">.us</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkBiz" />
                                <label className="form-check-label" htmlFor="neChkBiz">.biz</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkHouse" />
                                <label className="form-check-label" htmlFor="neChkHouse">.house</label>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <input type="checkbox" id="neChkSoy" />
                                <label className="form-check-label" htmlFor="neChkSoy">.soy</label>
                            </div>
                        </div>
                    </div>
                    <div id="neDomResults" className="container d-none">
                        <div className="row">
                            <div className="text-center" data-aos="zoom-in" data-aos-delay="200">
                                <h3 id="neDomReqName">Domain</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div id="neExtcom" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtcomTbl">
                                    <div className="col-md-4 text-end">.com</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtcomAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtcomNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtnet" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtnetTbl">
                                    <div className="col-md-4 text-end">.net</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtnetAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtnetNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtorg" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtorgTbl">
                                    <div className="col-md-4 text-end">.org</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtorgAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtorgNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="neExtinfo" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtinfoTbl">
                                    <div className="col-md-4 text-end">.info</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtinfoAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtinfoNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtus" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtusTbl">
                                    <div className="col-md-4 text-end">.us</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtusAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtusNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtbiz" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtbizTbl">
                                    <div className="col-md-4 text-end">.biz</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtbizAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtbizNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="neExthouse" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExthouseTbl">
                                    <div className="col-md-4 text-end">.house</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExthouseAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExthouseNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtsoy" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtsoyTbl">
                                    <div className="col-md-4 text-end">.soy</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtsoyAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtsoyNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="neExtme" className="col-md-4 text-center" data-aos="zoom-in" data-aos-delay="200">
                                <div className="row" id="neExtmeTbl">
                                    <div className="col-md-4 text-end">.me</div>
                                    <div className="col-md-8 text-start text-nowrap">
                                        <div className="row">
                                            <div className="col-md-6 d-none" id="neExtmeAV">
                                                <FormattedMessage id = "app.dom-available" defaultMessage="available" />
                                            </div>
                                            <div className="col-md-6 d-none" id="neExtmeNA">
                                                <FormattedMessage id = "app.dom-not-available" defaultMessage="Not available" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default injectIntl( Domain );