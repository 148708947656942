//--------------------------------//
//- Author: Francisco J. O'Meany -//
//--------------------------------//
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../netequal_tech.svg';
import { FormattedMessage } from 'react-intl';
import Utility from './UtilityComponent';


class Header extends Component {

    constructor(props) {
        super(props);
        this.Utl = new Utility();

        this.state = {
            isNavOpen: false,
            isModalOpen: false
        }
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    handleLogin(event) {
        this.toggleModal();
        alert("Username: " + this.username.value + " Password: " + this.password.value 
            + " Remember: " + this.remember.checked);
        event.preventDefault();
    }

    handleLanguage( event ) {
      let Utl = new Utility();
      let lng = Utl.getCookie( "neLang" );
      let language = ( lng === 'en' ? 'es' : 'en' );
      Utl.setCookie( "neLang", language, 30 );
      document.location.reload()
      event.preventDefault();
    }

    render() {
        return (
          
          <header id='header'>
            <Navbar id="navbar" collapseOnSelect fixed='top' expand="sm" data-bs-theme="dark" className='ne-navbar'>
            <Container>
              <Navbar.Brand href="#home"><img src={logo} alt='Netequal Technologies' className='ne-logo'/></Navbar.Brand>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
              <Navbar.Collapse id='responsive-navbar-nav' className="justify-content-end">
                <Nav>
                  <Nav.Link id="neHeroMenu" href="#neHero" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-home"
                        defaultMessage="Home"
                      />
                  </Nav.Link>
                  <Nav.Link href="#mission" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-mission"
                        defaultMessage="Mission"
                      />
                  </Nav.Link>
                      
                  <Nav.Link href="#services" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-services"
                        defaultMessage="Serv"
                      />
                  </Nav.Link>
                  <Nav.Link href="#portfolio" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-portfolio"
                        defaultMessage="Portfolio"
                      />
                  </Nav.Link>
                  <Nav.Link href="#about" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-aboutus"
                        defaultMessage="About Us"
                      />
                  </Nav.Link>
                  <Nav.Link href="#contact" className='me-2 scrollto'>
                      <FormattedMessage
                        id = "app.menu-contact"
                        defaultMessage="Contact"
                      />
                  </Nav.Link>
                  <Nav.Link href="#_" onClick={this.handleLanguage} className='me-2'>
                      <FormattedMessage
                        id = "app.menu-language"
                        defaultMessage="Español"
                      />
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        );
    };
}

export default Header;